.tt-menu {
	background-color: #fff;
	box-shadow: 4px 7px 10px 0px rgba(0, 0, 0, 0.2);
	border: 1px solid #8a8a8a;
	box-sizing: border-box;
	min-width: 100%;
	white-space: nowrap;
	padding: 8px;
    right: 0;
    left: auto!important;
}

@media(max-width:520px) {
    .tt-menu {
        right:auto;
        min-width:auto;
    }
}
.search-term.tt-hint {
	opacity: .4 !important;
}

.tt-menu *, .tt-menu *:before, .tt-menu *:after {
	box-sizing: border-box;
}

.tt-menu .tt-dataset {
	padding: 0 8px;
}

.tt-menu .tt-suggestion strong {
	color: #322ea4;
	font-weight: normal;
}

.search-wrapper .tt-menu .tt-suggestion.tt-cursor,
.search-wrapper .tt-menu .tt-suggestion:hover {
    background-color:rgba(123,122,154,0.1);
}

.tt-menu .tt-suggestion {
	cursor: pointer;
}

.tt-menu .category-title {
	color: #8a8a8a;
	display:inline-block;
	border:0 none;
	font-weight:300;
	margin-top:8px;
	margin-bottom:4px;
}

.tt-menu > .tt-dataset > *:not(.category-title) {
    padding-left: 16px;
    padding-top: 4px;
    padding-bottom: 2px;
    white-space:normal;
}

@media {
    .tt-menu > .tt-dataset > *:not(.category-title) {
        padding-top: 8px;
        padding-bottom: 8px;
    }
}

.tt-menu > .tt-dataset.tt-dataset-ALL.tt-dataset.tt-dataset-ALL > * {
	padding-left: 0;
}

